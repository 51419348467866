import { Button, Tooltip, useToaster } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { DivisionMensIcon } from '@enterprise-ui/icons'
import apiConfig from '../../../globalConfig/apiConfig'

const PresentationAttributes = ({ selectedItems }) => {
  const toaster = useToaster()
  const isPresentationAttributesDepartments = true
  const selectedTcins = selectedItems?.map((item) => item.tcin, [])
  const redirectToPresentationAttributesApp = async () => {
    try {
      const presentationAttributeAppBaseUrl = `${window.location.origin}`
      const presentationAttributeDetailsUrl = `${presentationAttributeAppBaseUrl}${apiConfig.presentationAttribute.domainPath}${apiConfig.presentationAttribute.managePath}/${selectedTcins}`
      window.open(
        presentationAttributeDetailsUrl,
        '_self',
        'noopener,noreferrer',
      )
    } catch (error) {
      toaster({
        type: 'error',
        heading: 'Error Redirecting to Presentation Attribute Application',
        message:
          error.message || 'An unknown error occurred while redirecting.',
      })
    }
  }

  const isCaseBackButtonDisabled =
    selectedItems?.length !== 0 && isPresentationAttributesDepartments

  return (
    <>
      <Tooltip
        content="Manage Presentation Attributes"
        location="top"
        data-testid="presentation-attributes-tooltip"
      >
        <Button
          type="ghost"
          onClick={redirectToPresentationAttributesApp}
          className="button-bg-hover-transparent"
          disabled={!isCaseBackButtonDisabled}
          data-testid="casepack-button"
          // style={{ visibility: 'hidden' }}
        >
          <EnterpriseIcon
            icon={DivisionMensIcon}
            size="md"
            className="hc-clr-contrast-weak"
          />
        </Button>
      </Tooltip>
    </>
  )
}

export default PresentationAttributes
